.about-us-nav-bg {
  width: 100%;
  height: 450px;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../../images/AboutHeader/MaskAbout.webp");
  background-position: center;
  margin-bottom: 60px;
}

.about-us-quote-img img {
  width: 50px;
  padding-top: 150px;
  position: fixed;
  justify-content: right;
  right: 0;
  z-index: 6;
}

.last-about-sec-pad {
  padding-bottom: 30px;
}

.about-us-text {
  color: rgb(255, 255, 255);
  font-size: 50px;
  font-weight: 700;
  padding-top: 180px;
}

.about-us-text1 {
  color: rgb(255, 255, 255,0.8);
  font-size: 16px;
  padding: 2px 170px 0px 170px;
}

/* nav-bg text */
.about-us-text-nav {
  font-size: 16px;
}

.about-us-text-nav-pad {
  padding-left: 96px;
  padding-right: 96px;
  padding-bottom: 40px;
  padding-top: 40px;
}

@media (min-width:350px) and (max-width:470px) {
  .about-us-nav-bg {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("./../../images/AboutHeader/MaskAbout.webp");
    background-position: center;
    margin-bottom: 60px;
  }

  .about-us-text {
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 700;
    padding-top: 130px;
  }
}