.footer-head-logo {
  img {
    width: 120px;
  }
}

.center-content h6 {
  font-weight: 600;
  font-size: 28px;
}

.footerline-img {
  background-color: #182d70;
  color: white;
  margin: 0;
  height: auto;
}

.footerline-img p {
  opacity: 0.5;
}

.footer-rights {
  font-size: 18px;
  justify-content: center;
  margin: auto;
  padding: 1%;
}

/* Media Query for 321px to 480px */
@media (min-width: 350px) {
  .center-content h6 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

.center-content-fh{
  justify-content: center;
}

  .center-content p {
    font-size: 16px;
  }

  .footerline-img {
    background-color: #182d70;
    color: white;
    margin: 0;
    height: auto;
  }

  .footerline-img p {
    opacity: 0.5;
    text-align: center;
  }

  .footer-rights {
    font-size: 12px;
    justify-content: center;
    margin: auto;
  }

  .center-content p {
    text-align: center;
    padding-bottom: 10px;
  }
}

/* Media Query for 481px to 600px */
@media (min-width: 460px) {
  .center-content h6 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
  }

  .center-content-fh{
    justify-content: center;
  }

  .footerline-img {
    background-color: #182d70;
    color: white;
    margin: 0;
    height: auto;
  }

  .footerline-img p {
    opacity: 0.5;
    text-align: center;
  }

  .footer-rights {
    font-size: 12px;
    justify-content: center;
    margin: auto;
    padding: 1%;
  }

  .center-content p {
    text-align: center;
  }
}
