.displayBlock {
    display: block;
    z-index: 112;
  }
  
  .displayNone {
    display: none;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .modalMain {
    position: fixed;
    background: linear-gradient(to top, #193377, #009ADB);
    width: 100%;
    max-width: 450px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .closeButton:hover {
    color: white;
  }

  @media (max-width: 460px) {
    .modalMain {
      position: fixed;
      background: linear-gradient(to top, #193377, #009ADB);
      width: 80%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 9px;
      border-radius: 20px;
    }

    .closeButton {
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }