.blogs-bg-main-bg {
  background: linear-gradient(to right, #193377, #0086bf);
  background-size: contain;
  padding: 40px 0;
  margin-bottom: 50px;
}

.blogs-bg-main-bg h1 {
  color: #ffffff;
  text-align: center;
  /* padding-top: 60px; */
  padding-bottom: 40px;
  font-size: 60px;
}

.card-blog-main {
  transition: transform 0.2s, box-shadow 0.2s;
}

.card-blog-main:hover {
  transform: scale(1.05);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.view-more-blogs {
  padding-top: 40px;
}

.view-all-blogs {
  color: white;
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.view-all-blogs:hover {
  color: #ffffff;

  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.view-all-blogs .icon-arrow {
  background: #ffffff;
  color: #2044a2;

  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.view-all-blogs:hover .icon-arrow {
  background: #2044a2;
  color: white;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
}
.view-all-blogs:hover .icon-arrow i {
  font-weight: 800;
}

.card-blog-main-second {
  justify-content: center;
}

.next-prev-blog {
  padding-top: 25px;
  padding-bottom: 25px;
}

.card-title-wrapper .card-title{
  font-size: 12px;
}
.card-title-wrapper p { 
  width: 100%;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

@media (max-width: 575.98px) {
  .blogs-bg-main-bg h1 {
    color: #ffffff;
    text-align: center;
    /* padding-top: 40px;
    padding-bottom: 30px; */
    font-size: 35px;
  }
}

.market-blog-card {
  transition: transform 0.2s ease;
}

.market-blog-card:hover {
  transform: scale(1.02); 
}

