.section {
  margin-bottom: 10px;

  margin-bottom: 16px;
  .section-background {
    width: 100%;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    object-fit: cover;
    display: none;
  }

  .single-row {
    margin-bottom: 20px;
  }
  .single-card {
    height: 100%;
    border: none;
    padding: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    .card-image {
      max-width: 100%;
      height: auto;
    }

    &:hover {
      background-color: #f1f1f1;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      transform: translateY(-5px);
    }
  }
}

@media (min-width: 992px) {
  .section {
    background-image: url("../../../public/images/CargoService/cargo-mixed.webp");
    background-size: 100% 75%;
    background-position: center;
    background-repeat: no-repeat;
    .section-background {
      display: block;
    }
  }
}

@media (min-width: 1400px) {
  .height-95 {
    height: 95% !important;
  }
  // .section {
  //   // height: 70vh;
  //   // margin-bottom: 180px;
  // }
}
@media (min-width: 1600px) {
  .height-95 {
    height: 95% !important;
  }
  // .section {
  //   // height: 100vh;
  //   margin-bottom: 0px;
  // }
}
