.page-main-container {
  width: 100%;
  height: 450px;
  .page-main-title {
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 700;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .page-main-container {
    max-width: 100%;
    height: 300px !important;
    .page-main-title {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .page-main-container {
    width: 100%;
    height: 450px;
    .page-main-title {
      color: rgb(255, 255, 255);
      font-weight: 700;
    }
  }
}
