.network {
  background: linear-gradient(45deg, #193377, #077bbd);
  background-image: url('./../../images/Network/Map-global.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding: 70px 11px;
  .map {
    margin-bottom: 70px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 992px) {
  .network {
    height: 100vh;
    padding: 0;
  }
}

.activeLocation {
  font-weight: normal;
  font-size: 13px; 
}

.activeLocation.active {
  font-weight: bold;
  font-size: 16px;
}


