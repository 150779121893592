.network-bg-main {
  width: 100%;
  height: 75vh;
  background: url("../images/Network/GlobalMap-new.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.focus-outline-none:focus,
.focus-outline-none:focus-visible {
  outline: none;
}

.map-svg {
  cursor: pointer;
  width: 95%;
  height: 95%;
}

@media (min-width: 576px) {
  .map-svg {
    width: 75%;
    height: 76%;
  }
}
@media (min-width: 992px) {
  .network-bg-main {
    height: 100vh;
  }
  .map-svg {
    width: 75%;
    height: 76%;
  }
}
@media (min-width: 1400px) {
  .map-svg {
    width: 75%;
    height: 76%;
  }
}

.hover-shadow {
  filter: drop-shadow(14px 5px 4px rgba(0, 0, 0, 0.5));
}
