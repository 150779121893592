body {
  margin: 0 !important;
}

p, h2, h3, h4, h5 ,h6 {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 800 !important;
}

.pagination-button-blogs {
  transition: transform 0.3s ease-in-out;
}

.pagination-button-blogs:hover {
  transform: scale(1.05);
}

button {
  outline: none!important;
}

button:focus {
  outline: none!important;
}
