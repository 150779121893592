.contact-us-nav-bg {
  width: 100%;
  height: 450px;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/Contact-us/contact-us-group.webp");
  background-position: center;
}

.contact-us-quote-img img {
  width: 50px;
  padding-top: 150px;
  position: fixed;
  justify-content: right;
  right: 0;
}

.contact-us-text {
  color: rgb(255, 255, 255);
  font-size: 50px;
  font-weight: 700;
  padding-top: 180px;
}

@media (min-width:350px) and (max-width:470px) {
  .contact-us-nav-bg {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../images/Contact-us/contact-us-group.webp");
    background-position: center;
  }
  
  .contact-us-text {
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 700;
    padding-top: 130px;
  }
}
