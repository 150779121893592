.social-icons .nav-link-navbar-main {
  font-size: 14px;
}

.home-navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 110;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  
}
