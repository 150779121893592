.not-found-bg{
    background: url("/public/images/404.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    color: white;
    font-family: 'Poppins', sans-serif;
}
  
  .not-found-head {
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-button {
    padding-top: 18px;
  }
  
  .not-found-button-size{
    padding: 8px 25px 8px 25px;
    border-radius: 0;
  }
