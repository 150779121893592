.split-form-container {
  display: flex;
  min-height: 100vh;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px;
  // padding-top: 70px;
  .split-form-image,
  .split-form {
    width: 100%;
  }
}

.split-form-image {
  flex: 1;
  background-image: url("../images/Forms/form-bg.webp"),
    linear-gradient(to bottom, #193377, #009adb);
  background-size: cover;
  background-repeat: no-repeat;
  height: 750px;
  padding: 0;
  margin: 0;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the animation to the thank-you message div */
.thank-you-message-main-form-one {
  animation: zoomIn 0.5s ease forwards;
}

/* Additional styles to center the content and set background color */
.thank-you-message-main-form-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-label {
  font-weight: 600;
  font-size: 15px;
}

.split-form {
  background-color: #ececec;
  background-image: url("../images/Forms/form-bg-2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 750px;
  display: flex;
  flex-direction: column; /* Display form elements in a column */
  align-items: center;
  justify-content: center;
  /* Center align form elements horizontally */
  .sub-title {
    font-size: 40px;
    font-weight: 400; /* Add space below the heading */
  }
}

.split-first-head {
  font-size: 30px;
  color: white;
}

.split-form h2 {
  color: white;
  font-weight: 600; /* Add space below the heading */
  text-align: left;
  font-size: 50px;
}

.split-form h2 span {
  color: white;
  font-weight: 600;
}

.transparent-input {
  background-color: transparent !important;
  width: 100%; /* Make input fields expand to the container's width */
  margin-bottom: 17px;
  padding-right: 150px; /* Add space between input fields */
}

.split-form label {
  color: rgb(228, 228, 228);
  float: left;
  height: 19px;
}

.transparent-input::placeholder {
  color: rgba(243, 243, 243, 0.3);
  font-size: 13px;
}

.get-a-quote-btn {
  padding: 10px 30px;
  background-color: transparent;
  border-radius: 10px;
  border-color: white;
  width: auto; /* Allow button to adjust its width based on content */
  color: white;
  transition: all 0.3s ease-in-out;
}
.get-a-quote-btn:hover {
  background-color: white;
  font-weight: 700;
  color: #2044a2;
}

/* Media queries for responsiveness */

@media (max-width: 768px) {
  .split-form {
    padding: 40px; /* Increase padding for smaller screens */
    height: auto; /* Allow the form to expand in height */
  }

  .split-form-image {
    display: none; /* Hide the image on smaller screens */
  }
}

@media (max-width: 460px) {
  .split-form br {
    display: none;
  }

  .split-form label {
    text-align: left;
    float: left;
  }

  .split-first-head {
    font-size: 20px;
    color: white;
  }

  .split-form h2 {
    padding-top: 0px;
    font-size: 35px;
  }
}

.form-market input {
  border-color: rgb(199, 198, 198);
}

.form-market textarea {
  border-color: rgb(199, 198, 198);
}

@media (min-width: 1024px) {
  .split-form-container {
    .split-form-image,
    .split-form {
      width: 50%;
    }
  }
}

.g-recaptcha {
  transform: scale(1.4);
  transform-origin: 0 0;
}
