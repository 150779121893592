.specialised-service {
  .image-cards-container {
    color: white;
    margin-bottom: 30px;
  }
  .image-cards-container:nth-child(1),
  .image-cards-container:nth-child(3) {
    .image-cards-left {
      height: 400px;
    }
    .image-cards-right {
      // background: transparent;
      color: white;
    }
    .image-cards-right {
      h1 {
        font-size: 28px;
      }
    }
  }
  .image-cards-container:nth-child(2) {
    .image-cards-left {
      background: transparent;
      color: #000;
      order: 2;
    }
    .image-cards-right {
      height: 400px;
      order: 1;
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 768px) {
  .specialised-service {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3) {
      .image-cards-left {
        display: flex;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        order: 2;
      }
    }
  }
}
@media (min-width: 992px) {
  .specialised-service {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3) {
      .image-cards-left {
        display: flex;
        height: auto;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        height: auto;
        order: 2;
      }
    }
  }
}

.service-row-heading {
  color: rgb(14, 14, 14);
}

@media (min-width: 576px) {
  .service-row-heading {
    color: rgb(255, 255, 255);
  }
}
