.aboutnew-story-bg {
  background-image: url("../../../images/AboutStory/spare-trans.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutnew-story-text {
  color: white;
  padding-right: 20px;
}

.aboutnew-story-text p {
  padding-top: 10px;
}

@media (min-width: 360px) and (max-width: 760px) {
  .aboutnew-story-text h2 {
    padding: 0px 10px 10px 10px;
  }

  .aboutnew-story-text p {
    padding: 0px 10px 10px 10px;
  }

  .aboutnew-story-bg {
margin-bottom: 50px;
  }
  
}
