.market-update-card {
  height: 100%;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  .card-image {
    max-width: 100%;
    height: auto;
  }
  .card-title-wrapper p {
    width: 100%;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  &:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transform: translateY(-5px);
  }
}

.market-u-top-h{
padding-top: 100px;
padding-bottom: 80px;
}

@media (min-width:350px) and (max-width:470px){
  .market-u-top-h{
    padding-top: 50px;
    padding-bottom: 30px;
    }
}

@media (min-width:470px) and (max-width:700px){
  .market-u-top-h{
    padding-top: 50px;
    padding-bottom: 30px;
    }
}

// Blog
.blog-wrapper {
  margin-bottom: 50px;
}

.mu-header-icons{
  transition: transform 0.3s ease;
  width: 19px;
}

.mu-header-icons:hover {
  transform: scale(1.1); 
}
