.card-container-gallery {
  border: 0px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
}

.card-container-gallery:hover {
  transform: scale(1.05);
}

.cards-gallery-main {
  padding-top: 60px;
  padding-bottom: 60px;
}

.hovered-tab {
  color: #2044a2;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-thumbnail-link {
  position: relative;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-modal-content {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 50%;
  max-height: 60%;
}

.custom-carousel-root {
  max-width: 100%;
  max-height: 100%;
}

.close-button-gallery {
  position: absolute;
  top: 14px;
  right: 15px;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  border: 4px solid white;
  border-radius: 50%;
  padding: 0px 13px 5px 13px;
  cursor: pointer;
  color: white;
  outline: none;
}

.close-button-gallery:hover {
  color: #2044a2;
}

.zoom-on-hover-card {
  transition: transform 0.3s ease-in-out;
}

.zoom-on-hover-card:hover {
  transform: scale(1.1);
}

.video-button-gallery {
  color: black;
  font-size: 14px;
  border: none;
  font-weight: 650;
}

.video-button-gallery:hover {
  color: rgb(255, 255, 255);
  font-size: 14px;
  border: none;
  background-color: #2044a2;
  font-weight: 700;
}

.cards-gallery-main {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (max-width: 1200px) {
  .custom-modal-content {
    max-width: 50%;
  }
  .cards-gallery-main {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 992px) {
  .custom-modal-content {
    max-width: 94%;
  }
  .cards-gallery-main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .close-button-gallery {
    font-size: 14px;
    padding: 0px 8px 2px 8px;
  }
  .cards-gallery-main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 360px) {
  .cards-gallery-main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.gallery-carousel-image {
  width: 100%;
  height: 500px;
  object-position: top center;
  object-fit: cover;
}

@media screen and (min-width: 360px) and (max-width: 760px) {
  .gallery-carousel-image {
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
  }
}
