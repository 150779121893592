.services-slider .swiper-slide {
  width: 385px;
}

.car-service-card-meds-heading {
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}

.img-card-serve {
  width: 80%;
}

.car-service-cards-bold {
  padding-bottom: 30px;
}

.car-service-card-meds-bold {
  font-size: 18px;
  text-align: center;
}

.car-service-card-meds {
  padding-top: 2%;
  align-items: center;
}

.car-wrapper {
  position: relative;
  padding: 0;
}

.arrows-service {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 40px;
}

.arrow-icon {
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  font-size: 35px;
  position: absolute;
  transition: transform 0.1s linear;
  z-index: 1;
}

.carousel-servicess {
  display: flex;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  margin: 10px 40px 10px 40px;
  list-style: none;
}

.carousel-container {
  width: 100%;
  position: relative;
}

.carousel-servicess::-webkit-scrollbar {
  display: none;
}

.carousel-servicess.no-transition {
  scroll-behavior: auto;
}

.carousel-servicess.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}

.carousel-servicess.dragging .card-med {
  cursor: grab;
  user-select: none;
}

.card-med .car-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-med {
  /* scroll-snap-align: start; */
  border-radius: 10px;
  height: 365px;
  cursor: pointer;
  width: 100%;
  min-width: 280px;
  box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.151);
  border: none;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

.card-med .car-img {
  /* width: 14%; */
  object-fit: cover;
}

.card-med:hover {
  background-color: rgb(255, 255, 255);
  border: 0;
  box-shadow: 0px 2px 0px 0px (255, 255, 255, 0.4);
  transform: scale(1.05);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  height: 342px;
}

.card-med h2 {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #193579;
}

.card-med p {
  display: block;
  color: #6a6d78;
  font-size: 14px;
  text-align: center;
  width: 70%;
}

.arrow-two-s {
  padding-left: 30px;
}

.arrow-one-s {
  padding-right: 110px;
}

.car-service-cards-heading {
  text-align: center;
  font-size: 70px;
}

.car-service-cards {
  padding-bottom: 5px;
}

@media (max-width: 460px) {
  .car-service-cards-bold {
    padding-bottom: 10px;
  }

  .car-service-cards-heading {
    text-align: center;
    font-size: 35px;
  }

  .carousel-servicess {
    display: flex;
    grid-auto-flow: column;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin: 0;
    list-style: none;
  }
}

.service-swiper-wrapper {
  max-width: 93%;
  margin: 0 auto;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
  overflow: "auto";
}

.slide {
  width: calc(100% / 3);
}

.card-wrapper {
  flex: 0 0 33.33%;
  box-sizing: border-box;
}

.services-slider {
  transition: transform 0.3s ease;
}

.services-slider.active {
  transform: translateX(calc(-33.33% * var(--currentIndex)));
}
