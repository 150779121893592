.about-bg-team {
  background: url("../../images/AboutTeam/about-bg-team.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  justify-content: center;
}
.team-parag-team {
  color: white;
  padding-bottom: 50px;
  font-size: 18px;
}

.about-team-head {
  color: white;
  padding-top: 40px;
  text-align: center;
}

.about-team-clr {
  color: white;
  padding-bottom: 15px;
}

.team-members-team {
  padding-bottom: 15px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.team-members-team:hover {
  filter: grayscale(0%);
}

.team-parag-team {
  text-align: center;
  padding: 12px 60px 15px 60px;
}

.team-members-team {
  width: 100%;
}

.team-about-pad {
  padding-bottom: 20px;
}
.about-team-clr-one {
  color: white;
}

/* Media Query for 320px and below */
@media (min-width: 300px) and (max-width: 320px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

/* Media Query for 321px to 480px */
@media (min-width: 321px) and (max-width: 480px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

/* Media Query for 481px to 600px */
@media (min-width: 481px) and (max-width: 600px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

/* Media Query for 601px to 768px */
@media (min-width: 601px) and (max-width: 768px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

/* Media Query for 769px to 900px */
@media (min-width: 769px) and (max-width: 900px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

/* Media Query for 1025px to 1200px */
@media (min-width: 1025px) and (max-width: 1200px) {
  .team-parag-team {
    color: white;
    padding: 12px 12px 12px 12px;
    font-size: 16px;
  }

  .about-team-head {
    color: white;
    padding-top: 30px;
    text-align: center;
  }

  .about-team-clr {
    color: white;
    padding-bottom: 15px;
  }

  .team-parag-team {
    text-align: center;
    padding-top: 12px;
  }

  .team-about-pad {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .about-bg-team {
    background-size: 100% 75%;
  }
  .about-team-clr-one {
    color: black;
  }
}
@media (min-width: 1200px) {
  .about-bg-team {
    background-size: 100% 75%;
  }
  .about-team-clr-one {
    color: black;
  }
}

// new

.about-team-new {
  background: url("../../images/AboutTeam/about-bg-team.webp");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  justify-content: center;
}

.team-border {
  background: #fff;
  color: black;
}

.team-border:hover {
  border: none;
  background: linear-gradient(to bottom, #2b4588, #009adb);
  color: white;
}

.team-container-new {
  margin-bottom: 80px;
}

.team-name-position h5 {
  padding-top: 10px;
  font-size: 16.5px;
  font-weight: 600;
}

.team-name-position p {
  font-size: 15px;
  font-weight: 400;
}

@media (min-width: 321px) and (max-width: 490px) {
  .team-name-position h5 {
    padding-top: 10px;
    font-size: 14.5px;
    font-weight: 600;
  }

  .team-name-position p {
    font-size: 13px;
    font-weight: 400;
  }

  .about-team-new {
    margin-bottom: -25px;
  }
}
